body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.font-xs {
    font-size: 0.75rem;
}

.font-sm {
    font-size: 0.85rem;
}

.font-lg {
    font-size: 1rem;
}

.font-xl {
    font-size: 1.25rem;
}

.font-2xl {
    font-size: 1.5rem;
}

.font-3xl {
    font-size: 1.75rem;
}

.font-4xl {
    font-size: 2rem;
}

.font-5xl {
    font-size: 2.5rem;
}

.font-w-600 {
    font-weight: 600;
}

.label {
    // font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
}

.break-word {
    word-break: break-all;
}

.line-through {
    text-decoration: line-through solid rgb(68, 68, 68);
}
